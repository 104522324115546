.editorial .tiles .zoomcontainer > div > div > div {
  font-weight: bold;
}

body{
	font-family: $font-family-sans-serif2;
}

h1 {
  font-family: gotham-bold;
  color: $brand-primary;
  &.color-inherit {
    color: inherit;
  }
}

h2, h3 {
  font-family: gotham-medium;
  font-weight: normal !important;
  color: $brand-primary;
  &.color-inherit {
    color: inherit;
  }
}

div[style="color:rgba(101,101,101,1.0);"] {
  h1, h2, h3 {
    color: $brand-primary;
  }
}

header #mainMenu #menuEntryContainer .menuEntry>a {
  color: $menuEntryLink;
  &.hover-highlight {
    color: $white;
    background-color: $orange2;
    &:hover{background-color:$btn-orange-bg-hover;}
  }
}
#mobileMenu .scrollable ul li {
  &.hover-highlight {
    color: $white;
    background-color: $orange2;
    &:hover{background-color: $btn-orange-bg-hover;}
  }
}

.tileFilter.chooseFilter .filterContainer .filterInnerContainer .slider {
	.ui-widget-header{
		background-color: $red;
	}
	span.ui-slider-handle{
		background-color: $red;
	}
	.ui-slider-pip-inrange{
		span.ui-slider-line-long {
			background: $red;
		}
		span.ui-slider-line {
			background: $red;
		}
	}
}
.editorial{
	#carousel-wrapper {
		font-family: gotham-medium;
		.btn {
			font-family: gotham !important;
		}
	}
}

div.h3{
	color:#656565 !important;
	font-family: gotham-medium !important;
	font-weight: normal !important;
}

.btn{
	font-family: gotham !important;
	font-weight: normal !important;
}
