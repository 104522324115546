// Overlay specific variables,
// will overwrite "_variables.scss"

// Font

@font-face {
  font-family: gotham;
  src: url('../fonts/GothamRounded-Book.otf');
}
@font-face {
  font-family: gotham-medium;
  src: url('../fonts/GothamRounded-Medium.otf');
}
@font-face {
  font-family: gotham-bold;
  src: url('../fonts/GothamRounded-Bold.otf');
}

$font-family-sans-serif2:     gotham, Helvetica, Arial, sans-serif !default;  

// Colors

$red: 							#ee3843;
$orange2:						#f9a336;

$brand-primary: 				#afca0b;
$brand-dark: 					#afca0b;
$menuEntryLink:					#656565;

$brand-secondary:				$brand-primary;
$brand-secondary-hover:			$brand-dark;
$footer-impressum-color: 		$brand-primary;
$footer-impressum-separator:	$white;

$link-color: 					$brand-primary;
$link-hover-color:           	darken($link-color, 15%);
$carousel-control-color:		$brand-primary;

$bg-newsletterContainer: 		$brand-primary;
$bg-paymentContainer: 			$brand-dark;
$bg-icon-newsletter: 			$red;

$btn-primary-color:             #fefeff;
$btn-primary-bg:                $brand-primary;
$btn-primary-bg-hover:			$brand-dark;
$btn-primary-border:            $brand-primary;

$btn-orange-bg-hover:			$red;
$btn-orangeNormal-bg-hover:		$red;

$icons-sprite: 			url('/images/sprite_spieleland.png');
$icons-sprite_2x: 		url('/images/sprite_spieleland@2x.png');

$icon-select: 			url('/images/select_spieleland.png');
$icon-select_2x: 		url('/images/select_spieleland@2x.png');
$icon-select_1: 		url('/images/select_1_spieleland@2x.png');
$icon-selectClose: 		url('/images/selectClose_spieleland@2x.png');
$icon-selectClose_2x: 	url('/images/selectClose_spieleland@2x.png');

$icon-check: 			url('/images/check_spieleland.png');
$icon-radio: 			url('/images/radio_spieleland.png');
